import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_screen_component_vue = _resolveComponent("load-screen-component-vue")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_load_screen_component_vue, null, null, 512), [
            [_vShow, _ctx.isLoading]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_router_outlet, { id: "main-content" })
    ]),
    _: 1
  }))
}