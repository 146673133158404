
  import { defineComponent } from "vue";
  import { IonImg, IonTitle } from "@ionic/vue";  
  
  export default defineComponent({
    components: {
      IonImg,
      IonTitle
    }
  });
  