import store from "@/store";
import { CapacitorHttp, HttpOptions } from "@capacitor/core"
import router from "@/router";

export default {
  async makeRequest(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, data?: any, params?: any,) {

    const headers: any = {
      Accept: "application/json",
      "Content-type": "application/json",
    };

    if (store.getters.tenantId) {
      headers["X-tenant"] = store.getters.tenantId;

      if (store.getters.apiKey) {
        headers["Authorization"] = 'Bearer ' + store.getters.apiKey;
      }
    }

    const options: HttpOptions = {
      url: process.env.VUE_APP_API_BASE_URL + url,
      method,
      headers,
      params,
      data
    }

    const request = await CapacitorHttp.request(options)

    if (request.status === 200 || request.status === 201) {
      return Promise.resolve(request)
    } else if (request.status === 401) {
      store.dispatch('logout');
      router.push("/auth");

      return Promise.reject(request)
    } else {
      return Promise.reject(request)
    }
  }
}