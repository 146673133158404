import { AppStoreState } from "@/interfaces/store";
import { Module } from "vuex";
import { Preferences } from "@capacitor/preferences";
import i18n from "@/i18n";

const appModule: Module<AppStoreState, AppStoreState> = {
    state: {
        initialized: false,
        locale: null,
        shouldShowLoadingScreen: false,
        unseenNotificationsCount: 0,
        modalInstances: []
    },
    mutations: {
        pushModal(state, payload) {
            state.modalInstances.push(payload);
        },
        popModal(state) {
            state.modalInstances.pop();
        },
        clearAllModalInstances(state) {
            state.modalInstances = [];
        },
        setInitialized(state, payload) {
            state.initialized = payload;
        },
        setLocale(state, payload) {
            state.locale = payload;
            Preferences.set({ key: 'jvo-locale', value: payload });
            i18n.global.locale = payload;
        },
        setShouldShowLoadingScreen(state, payload) {
            state.shouldShowLoadingScreen = payload;
        },  
        setUnseenNotificationsCount(state, payload) {
            state.unseenNotificationsCount = payload;
        },
        resetAppState(state) {
            state.locale = null;
            state.unseenNotificationsCount = 0;
        }
    },
    actions: {
        pushModal(context, payload) {
            context.commit("pushModal", payload);
        },
        popModal(context) {
            context.commit("popModal");
        },
        clearAllModalInstances(context) {
            context.commit("clearAllModalInstances")
        },
        setInitialized(context, payload) {
            context.commit("setInitialized", payload);
        },
        setLocale(context, payload) {
            context.commit("setLocale", payload);
        },
        setShouldShowLoadingScreen(context, payload) {
            context.commit("setShouldShowLoadingScreen", payload)
        }, 
        setUnseenNotificationsCount(context, payload) {
            context.commit("setUnseenNotificationsCount", payload);
        }, 
    },

    getters: {
        initialized(state) {
            return state.initialized;
        },
        modalInstances(state) {
            return state.modalInstances;
        },
        locale(state) {
            return state.locale;
        },
        shouldShowLoadingScreen(state) {
            return state.shouldShowLoadingScreen;
        },
        unseenNotificationsCount(state) {
            return state.unseenNotificationsCount;
        }
    },
};

export default appModule;
