export default {
  "api": {
    "errors": {
      "404": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["De ", _interpolate(_named("model")), " is niet gevonden!"]), _normalize(["Er zijn geen ", _interpolate(_named("model")), " gevonden!"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps! Er is een fout opgetreden. Probeer het later opnieuw."])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het aanvragen van een wachtwoord reset-link."])},
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord kon niet aangepast worden, probeer het later opnieuw."])},
      "not_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet geregistreerd voor deze campagne of er staat een aanvraag uit die reeds geaccepteerd dient te worden."])},
      "no_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen artikelen gevonden!"])}
    },
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw proberen"])},
    "success": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wijzigingen zijn succesvol doorgevoerd!"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien uw mailadres bij ons bekend is ontvangt u een wachtwoord reset-link in uw mailbox!"])},
      "mark_task_as_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw taak is succesvol als afgerond gemarkeerd. Een administrator dient dit te bevestigen alvorens u de punten ontvangt."])},
      "minute_log_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw uren zijn succesvol toegevoegd!"])},
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is succesvol aangepast!"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is succesvol een aanvraag ingediend!"])}
    }
  },
  "auth": {
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar inloggen"])},
    "edit_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord wijzigen"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen regio"])}
  },
  "avatar": {
    "categories": {
      "antenna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antenne"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lichaam"])},
      "ears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oren"])},
      "eyes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogen"])},
      "mouth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mond"])},
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofd"])},
      "neck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nek"])},
      "nose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neus"])}
    }
  },
  "campaign": {
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten"])}
  },
  "form": {
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvragen"])}
    },
    "fields": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uren"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord bevestigen"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regio"])}
    },
    "errors": {
      "wrong_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze inloggegevens zijn bij ons niet bekend"])}
    }
  },
  "models": {
    "article": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Artikel"]), _normalize(["Artikelen"])])},
    "campaign": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Campagne"]), _normalize(["Campagnes"])])},
    "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker"]), _normalize(["Gebruikers"])])},
    "notification": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Notificatie"]), _normalize(["Notificaties"])])}
  },
  "navigation": {
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar wijzigen"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een valide e-mailadres te zijn"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld dient minimaal ", _interpolate(_named("min")), " karakters lang te zijn"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld dient maximaal ", _interpolate(_named("max")), " karakters lang te zijn"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De waarde van dit veld moet groter zijn dan ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De waarde van dit veld moet kleiner zijn dan ", _interpolate(_named("max"))])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld moet dezelfde waarde hebben als het wachtwoord veld"])},
    "timeStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient per 10 minuten ingevuld te worden. Je laatste getal moet dus een 0 zijn."])}
  }
}