export default {
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No region"])}
  },
  "form": {
    "fields": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])}
    },
    "errors": {
      "wrong_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials do not match our records"])}
    }
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field needs to be a valid email"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field needs a minimum length of ", _interpolate(_named("min")), " characters"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field needs a maximum length of ", _interpolate(_named("min")), " characters"])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field needs a minimum value of ", _interpolate(_named("min"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field needs a maximum value of ", _interpolate(_named("max"))])}
  }
}