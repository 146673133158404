import http from "@/utilities/api";
import IonicUi from "@/utilities/ui";
import { ErrorData } from "@/interfaces/api";
import store from "@/store";
import {AvatarComponent} from "@/interfaces/models";
import i18n from "@/i18n";

const ui = new IonicUi();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = i18n.global

export default {
    // Sends a http GET request to the server
    // Retrieves all avatar categories and components
    getAvatarIndex(errorData: ErrorData) {
        return new Promise(function (resolve, reject) {
            http.makeRequest('GET', "/avatar")
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    ui.createToast(
                        errorData.errorMessage,
                        true,
                        function () {
                            errorData.retryFunction();
                        }
                    );
                    reject(err);
                });
        });
    },

    // Sends a http PUT request to the server
    // Updates the users current avatar
    updateAvatar(errorData: ErrorData, successMessage: string) {
        const avatar = store.getters.avatarPreview.reduce((result: any, item: AvatarComponent) => {
            result[item.slug] = item.id;
            return result;
        }, {});

        return new Promise(function (resolve, reject) {
            http.makeRequest('PUT', "/avatar", avatar)
                .then((res) => {
                    if (res.status === 200) {
                        store.dispatch("setAvatar", res.data.avatar);
                        ui.createToast(successMessage, false);
                        resolve(res.data);
                    } else {
                        reject(new Error(`Unexpected response status: ${res.status}`));
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.status === 422) {
                        const validationErrors = err.data.errors;
                        console.error("Validation error:", validationErrors);
                        ui.createToast(
                            errorData.errorMessage = err.data.message,
                            true,
                            function() {
                                errorData.retryFunction();
                            }
                        );
                    } else {
                        console.error("An error occurred during the API request:", err);
                    }
                    reject(err);
                });
        });
    },


    // Calculate level
    calculateUserLevel(reputation: number) {
        return {
            level: reputation >= 500 ? Math.floor(0.09 * Math.sqrt(reputation)) : 1,
            progression: reputation >= 500 ? (((0.09 * Math.sqrt(reputation)) % 1) * 100) : (reputation / 500 * 100)
        }
    }
}