import http from "@/utilities/api";
import {
    LoginForm,
    PasswordForgotForm,
    ResetPasswordForm,
} from "@/interfaces/forms";
import store from "@/store";
import IonicUi from "@/utilities/ui";

const ui = new IonicUi();

export default {
    // Sends a http POST request to the server
    // Attempts a login
    // Return a promise
    login(payload: LoginForm) {
        return new Promise(function (resolve, reject) {
            http
                .makeRequest('POST', "/login", payload)
                .then((res: any) => {
                    store.dispatch('setApiKey', res.data.token);
                    store.dispatch('setUser', res.data.user);
                    store.dispatch("setAvatar", res.data.user.avatar);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // Sends a http GET request to the server
    // Retrieves the currently logged in user
    // Return a promise
    // On 2XX response store the data
    initialize() {
        return new Promise(function (resolve, reject) {
            http
                .makeRequest('GET', "/initialize")
                .then((res: any) => {
                    store.dispatch("setUser", res.data.user);
                    store.dispatch("setAvatar", res.data.user.avatar);
                    store.dispatch("setAvatarComponentCategories", res.data.avatarComponentCategories)
                    store.dispatch('setUnseenNotificationsCount', res.data.unseenNotificationsCount);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // Sends a http POST request to the server
    // Logout currently logged in user
    // Return a promise
    // On 2XX response empty store and push to auth page
    logout() {
        return new Promise(function (resolve, reject) {
            http
                .makeRequest('GET', "/logout")
                .then((res) => {
                    window.location.assign("/");
                    store.dispatch("setRedirectTo", "/");
                    store.dispatch("logout");
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    forgotPassword(payload: PasswordForgotForm, successMessage: string, errorMessage: string) {
        return new Promise(function (resolve, reject) {
            http
                .makeRequest('POST', "/forgot-password", payload)
                .then((res) => {
                    ui.createToast(
                        successMessage,
                        false
                    );
                    resolve(res);
                })
                .catch((err) => {
                    ui.createToast(
                        errorMessage,
                        true
                    );
                    reject(err);
                });
        });
    },

    resetPassword(payload: ResetPasswordForm, successMessage: string, errorMessage: string) {
        return new Promise(function (resolve, reject) {
            http
                .makeRequest('POST', "/reset-password", payload)
                .then((res) => {
                    ui.createToast(
                        successMessage,
                        false
                    );
                    resolve(res);
                })
                .catch((err) => {
                    ui.createToast(
                        errorMessage,
                        true
                    );
                    reject(err);
                });
        });
    },
};
