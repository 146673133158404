import { createStore } from "vuex";
import appModule from "./modules/app";
import authModule from "./modules/auth";
import avatarModule from "./modules/avatar";
import campaignModule from "./modules/campaign";
import navigationModule from "./modules/navigation";

export default createStore<any>({
  modules: {
    app: appModule,
    auth: authModule,
    avatar: avatarModule,
    campaign: campaignModule,
    navigation: navigationModule,
  },
  actions: {
    // Resets store for new user
    async logout(context) {
      await context.commit("resetAppState");
      await context.commit("resetAuthState");
      await context.commit("resetCampaignState");
      await context.commit("resetAvatarState");
    },
  }
});
