import { NavigationStoreState } from "@/interfaces/store";
import { Module } from "vuex";

const navigationModule: Module<NavigationStoreState, NavigationStoreState> = {
    state: {
        redirectTo: "/home",
    },
    mutations: {
        setRedirectTo(state, payload) {
            state.redirectTo = payload;
        },
    },
    actions: {
        setRedirectTo(context, payload) {
            context.commit("setRedirectTo", payload);
        },
    },
    getters: {
        redirectTo(state) {
            return state.redirectTo;
        },
    },
};

export default navigationModule;
