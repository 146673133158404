
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import LoadScreenComponentVue from "./components/common/LoadScreenComponent.vue";
import store from "./store";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    LoadScreenComponentVue,
  },
  setup() {
    // Component variables
    const isLoading = ref<boolean>(false);

    // Watch the current campaign and update if necessary
    watch(
      () => [store.getters.shouldShowLoadingScreen],
      function () {
        isLoading.value = store.getters.shouldShowLoadingScreen;
      },
      {
        immediate: true,
      }
    );

    return {
      isLoading,
    };
  },
});
