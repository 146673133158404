import { CampaignStoreState } from "@/interfaces/store";
import { Module } from "vuex";

const campaignModule: Module<CampaignStoreState, CampaignStoreState> = {
    state: {
        currentCampaign: null,
    },
    mutations: {
        setCurrentCampaign(state, payload) {
            state.currentCampaign = payload;
        },
        resetCampaignState(state) {
            state.currentCampaign = null;
        }
    },
    actions: {
        setCurrentCampaign(context, payload) {
            context.commit("setCurrentCampaign", payload);
        },
    },
    getters: {
        currentCampaign(state) {
            return state.currentCampaign;
        },
    },
};

export default campaignModule;
