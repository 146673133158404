import { createRouter } from "@ionic/vue-router";
import {
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store/index";
import { Preferences } from "@capacitor/preferences";
import AuthService from "@/services/AuthService";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/:catchAll(.*)*",
  //   redirect: "/404",
  // },
  // {
  //   path: "/404",
  //   component: () => import("@/views/404Page.vue"),
  // },
  {
    path: "/",
    component: () => import("@/views/MainTabs.vue"),
    meta: {
      authorize: true,
    },
    children: [
      {
        path: "",
        redirect: "/campaigns",
      },
      {
        path: "campaigns",
        component: () => import("@/views/campaigns/CampaignIndexPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "campaigns/:id",
        component: () => import("@/views/campaigns/ShowCampaignPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "campaigns/:id/dashboard",
        component: () => import("@/views/campaigns/CampaignDashboardPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "campaigns/:id/minute-logs",
        component: () => import("@/views/campaigns/MinuteLogsPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "campaigns/:id/articles",
        component: () =>
          import("@/views/campaigns/CampaignArticlesIndexPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "campaigns/:id/articles/:articleId",
        component: () => import("@/views/articles/ShowArticlePage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "articles",
        component: () => import("@/views/articles/ArticleIndexPage.vue"),
        meta: {
          authorize: true,
        },
      },
      {
        path: "notifications",
        component: () =>
          import("@/views/notifications/NotificationIndexPage.vue"),
        meta: {
          authorize: true,
        },
      },
    ],
  },

  {
    path: "/game-sessions/:id",
    component: () => import("@/views/game/GamePage.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    path: "/game-sessions/:id/result",
    component: () => import("@/views/game/ResultPage.vue"),
    meta: {
      authorize: true,
    },
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("@/views/auth/AuthPage.vue"),
  },
  {
    name: "forgot password",
    path: "/forgot-password",
    component: () => import("@/views/auth/ForgotPasswordPage.vue"),
  },
  {
    name: "reset password",
    path: "/reset-password",
    component: () => import("@/views/auth/ResetPasswordPage.vue"),
  },
];

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Check if user is authenticated, navigate to login if not (api/index.ts)
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const { authorize } = to.meta;

    if (!store.getters.initialized) {
      // Set cookies
      await Preferences.get({ key: "jvo-tenant-id" }).then((res) => {
        if (res.value) {
          store.dispatch("setTenantId", res.value);
        }
      });
      await Preferences.get({ key: "jvo-api-key" }).then((res) => {
        if (res.value) {
          store.dispatch("setApiKey", res.value);
        }
      });
      await Preferences.get({ key: "jvo-locale" }).then((res) => {
        if (res.value) {
          store.dispatch("setLocale", res.value);
        }
      });
      store.dispatch("setInitialized", true);
    }

    if (to.path !== "/auth") {
      store.dispatch("setRedirectTo", to.path);
    } else {
      if (store.getters.apiKey && store.getters.tenantId) {
        return next({ path: "/home" });
      }
    }

    if (authorize) {
      if (!store.getters.apiKey || !store.getters.tenantId) {
        return next({ path: "/auth" });
      } else if (!store.getters.user) {
        AuthService.initialize();
      }
    }

    return next();
  }
);

export default router;
