import store from "@/store";
import {
  ToastButton,
  toastController,
} from "@ionic/core/components";
import { modalController } from "@ionic/vue";

export default class IonicUi {
  // Setup for toast / notification creation
  async createToast(message: string, error: boolean, retryFunction?: any) {
    const buttons: ToastButton[] = retryFunction
      ? [
        {
          text: "Probeer opnieuw",
          role: "retry",
          handler: () => {
            retryFunction();
          },
        },
        {
          text: "X",
          role: "cancel",
        },
      ]
      : [];

    const toast: HTMLIonToastElement = await toastController.create({
      message,
      duration: retryFunction ? 0 : 4000,
      position: "bottom",
      color: error ? "danger" : "success",
      buttons: buttons,
    });
    await toast.present();
  }

  // Setup for modal creation
  async createModal(
    component: any,
    componentProps?: object,
    cssClass?: string
  ) {
    const modal: HTMLIonModalElement = await modalController.create({
      component: component,
      cssClass: cssClass ? cssClass : "",
      componentProps: componentProps,
    });
    await store.dispatch("pushModal", modal);
    modal.present();
  }

  // Dismis modal
  async dismisModal() {
    await store.dispatch("popModal");
    await modalController.dismiss();
  }

  // Close all open modals
  async closeAllModals() {
    await store.getters.modalInstances.forEach(function (e: HTMLIonModalElement) {
      e.dismiss();
    });
    await store.dispatch("clearAllModalInstances");
  }
}

