import {AvatarStoreState} from "@/interfaces/store";
import {Module} from "vuex";


const avatarModule: Module<AvatarStoreState, AvatarStoreState> = {
    state: {
        avatarComponentCategories: null,
        avatarPreview: null,
        avatar: null
    },
    mutations: {
        setAvatar(state, payload) {
            state.avatar = payload;
        },
        reorderAvatar(state, payload = false) {
            if (state.avatar && !payload) {
                state.avatar = state.avatar.sort((a, b) => {
                    return a.build_order - b.build_order;
                })
            } else if (state.avatarPreview && payload) {
                state.avatarPreview = state.avatarPreview.sort((a, b) => {
                    return a.build_order - b.build_order;
                })
            }
        },
        setAvatarComponentInPreview(state, payload) {
               if (state.avatarPreview) {
                state.avatarPreview.push(payload);
            }
        },
        unsetAvatarComponentInPreview(state, payload) {
            if (state.avatarPreview) {
                state.avatarPreview = state.avatarPreview.filter((component) => {
                    return component.slug !== payload;
                });
            }
        },
        clearAvatarPreview(state) {
            state.avatarPreview = null;
        },
        copyAvatarToPreview(state) {
            state.avatarPreview = state.avatar;
        },
        setAvatarComponentCategories(state, payload) {
            state.avatarComponentCategories = payload;
        },
        resetAvatarState(state) {
            state.avatarComponentCategories = null;
            state.avatarPreview = null;
            state.avatar = null;
        },

    },
    actions: {
        setAvatar(context, payload) {
            context.commit("setAvatar", payload)
            context.commit("reorderAvatar")
        },
        unsetAvatarComponentInPreview(context, payload) {
            context.commit("unsetAvatarComponentInPreview", payload)
            context.commit("reorderAvatar", true)
        },
        setAvatarComponentInPreview(context, payload) {
            context.commit("unsetAvatarComponentInPreview", payload.slug)
            context.commit("setAvatarComponentInPreview", payload)
            context.commit("reorderAvatar", true)
        },
        clearAvatarPreview(context) {
            context.commit("clearAvatarPreview")
        },
        copyAvatarToPreview(context) {
            context.commit("copyAvatarToPreview")
        },
        setAvatarComponentCategories(context, payload) {
            context.commit("setAvatarComponentCategories", payload);
        },
    },

    getters: {
        avatar(state) {
            return state.avatar;
        },
        avatarPreview(state) {
            return state.avatarPreview;
        },
        avatarComponentCategories(state) {
            return state.avatarComponentCategories;
        },
    },
};

export default avatarModule;
