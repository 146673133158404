import { AuthStoreState } from "@/interfaces/store";
import { Module } from "vuex";
import { Preferences } from "@capacitor/preferences";
import AvatarService from "@/services/AvatarService";

const authModule: Module<AuthStoreState, AuthStoreState> = {
  state: {
    user: null,
    level: null,
    apiKey: null,
    tenantId: null,
  },
  mutations: {
    setLevel(state, payload) {
      state.level = AvatarService.calculateUserLevel(payload).level;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setTenantId(state, payload) {
      state.tenantId = payload;

      Preferences.set({ key: "jvo-tenant-id",  value: payload })
    },
    setApiKey(state, payload) {
      state.apiKey = payload;
      
      Preferences.set({ key: 'jvo-api-key', value: payload });
    },
    async resetAuthState(state) {
      state.user = null;
      state.apiKey = null;

      await Preferences.remove({key: 'jvo-api-key'})
    }
  },
  actions: {
    setUser(context, payload) {
      context.commit("setUser", payload);
      context.commit("setLevel", payload.reputation)
    },
    setTenantId(context, payload) {
      context.commit("setTenantId", payload);
    },
    setApiKey(context, payload) {
      context.commit("setApiKey", payload);
    },
  },

  getters: {
    level(state) {
      return state.level
    },
    user(state) {
      return state.user;
    },
    tenantId(state) {
      return state.tenantId;
    },
    apiKey(state) {
      return state.apiKey;
    },
  },
};

export default authModule;
